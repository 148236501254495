import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import intlHook from 'imports/core/api/useIntl';

export const RenderPopupWhatspapp = (props) => {
  const { locale } = intlHook();
  if (locale !== 'fr') return props.children;
  return (
    <MarvelExperiment name="exp_career_finalize_pop_whatsapp">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="popup_plus_whatsapp">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
};
